import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import memberReducer from "../features/members/memberSlice";
import menuReducer from "../features/menu/menuSlice";
import uploadReducer from "../features/upload/uploadSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    members: memberReducer,
    upload: uploadReducer,
  },
});
