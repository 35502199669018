import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadService from "./uploadService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const uploadPDF = createAsyncThunk(
  "certificate/upload",
  async (PDFData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await uploadService.uploadPDF(PDFData, token);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Upload Image to Bucket
export const uploadFileToBucket = createAsyncThunk(
  "file/upload",
  async (docData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await uploadService.uploadFileToBucket(docData, token);
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    resetImage: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      //Upload PDF to data bucket
      .addCase(uploadPDF.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadPDF.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(uploadPDF.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //upload image to bucket
      .addCase(uploadFileToBucket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFileToBucket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(uploadFileToBucket.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.error;
        console.log("action rejected", action);
      });
  },
});

export const { resetImage } = uploadSlice.actions;

export default uploadSlice.reducer;
