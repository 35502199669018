// Stylesheet
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Form, Grid, Image, Popup, Segment } from 'semantic-ui-react';
import logo from "../assets/FL2F-logo.png";
import { login, reset } from "../features/auth/authSlice";
import "../styles/login.scss";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(message);
      return;
    }
    if (isSuccess && user && user !== null) {
      navigate("/");
    }

    dispatch(reset());
  }, [user, dispatch, navigate, isSuccess, isError, message]);

  const onClick = (e) => {
    if (!username || !password) {
      toast.error("Please fill in all the fields");
      return;
    }
    dispatch(login({ username: username, password: password }));
  };

  return (
    <Grid textAlign='center' style={{ height: '100vh'}} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
            <Image src={logo}  size='medium' verticalAlign='middle'/>
            <Divider hidden />
            <Segment loading={isLoading} basic>
              <Form size="huge">
                <Segment size="big">
                  <Form.Input label="Triz Analysis" fluid icon='user' iconPosition='left' placeholder='Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                  <Form.Input fluid icon='lock' iconPosition='left' placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                  <Button color='blue' fluid size='huge' onClick={onClick}>
                    Login
                  </Button>
                </Segment>
              </Form>
              <Divider horizontal>Or</Divider>
              <Form size='big'>
                <Segment>
                  <Popup flowing size="huge"
                    content='Not yet implemented, Coming soon!'
                    trigger={
                    <Button color='blue' fluid size='huge'>
                        Continue with Google
                    </Button>
                  }/>
                </Segment>
              </Form>
            </Segment>
        </Grid.Column>
    </Grid>
  )

};

export default Login;
