import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './Layout.css';
import { Outlet } from "react-router-dom";


function Layout() {

  return (
    <div className="container">
      <header className="mt-3 border-bottom">
        <img id="logo" src="fl2f-logo.png" alt="fl2f logo" width="150"/>
        <span className="header-text">
          <h1 className="fs-2 mb-1">TRIZ Analyzer</h1>
          <p className="caption">Powered by ChatGPT</p>
        </span>
      </header>

      <Outlet />

      <footer>
        <span>
          <p className="caption border-top">© 2024 From Lab 2 Fulfillment (FL2F). All rights reserved.</p>
        </span>
      </footer>
      
    </div>
  )

}

export default Layout;
        