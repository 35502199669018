import { useRef, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './Body.css';

function Body() {
    const inputFile = useRef(null);
    const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false);

    const handleClick = () => {
        inputFile.current.click();
    };

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      console.log('Selected file:', selectedFile);

      if (selectedFile !== undefined) {
        setUploadBtnDisabled(true); 

        const formData = new FormData();
        formData.append('file', selectedFile);

        fetch('http://localhost:5000/api/upload', {
            method: 'POST',
            body: formData
        })
        .then(async response => {
            // If response is not ok, handle error
            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage);
            }
            return response.blob();
        })
        .then(blob => {
            // Create a blob from the response data
            const url = window.URL.createObjectURL(new Blob([blob]));

            const a = document.createElement('a');
            a.href = url;
            a.download = 'TRIZ_report.pdf'; 

            // Automatically click the anchor element to initiate the download
            a.click();

            // Clean up: revoke the URL to release the resources
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            // This will be executed after the fetch operation completes
            setUploadBtnDisabled(false);
        });
      }

    };
      
    return (
        <div className="text-center mt-5">
            <input type='file' id='file-input' ref={inputFile} onChange={handleFileChange}/>
            <button type="button" id='upload-btn' className="btn btn-primary" onClick={handleClick} disabled={uploadBtnDisabled}>Upload a patent</button>
            {uploadBtnDisabled && 
                <span>
                    <p>Generating report...</p>
                    <p>(it will be automatically downloaded when finished)</p>
                </span>
            }
        </div>
    );
  }

export default Body;
