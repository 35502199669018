import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoutes from "./features/utils/PrivateRoutes";
import Body from './Body';
import Layout from './Layout';
import Login from './pages/Login';
function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}> {/* Wrap protected routes inside PrivateRoutes */}
            <Route element={<Layout />}>
              <Route path="/" element={<Body />} />
            </Route>
          </Route>
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
